section.homeSection {
  max-height: 800px;
  padding-top: 150px;
  width: 100vw;
  background-color: #f6f6f6;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homeSection .mainContainer {
  max-width: 1700px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.hero-img {
  width: 500px;
  z-index: 2;
}
section.homeSection .content {
  padding: 1em;
  display: flex;
  width: calc(100vw - 500px - 2em);
  z-index: 3;
}

.homeSection .slideContainer {
  display: flex;
  flex-direction: column;
}

.homeSection .homeSectionSwiper {
}

.homeSection .content h1 {
  /* font-size: 2.2rem; */
  margin: 1em 0em;
}
/* .homeSection .content p {
  font-size: 1.2rem;
  font-weight: 600;
} */

/* .line {
        position: absolute;
        height: 250px;
        width: 100%;
        bottom: 0;
        text-align: center;
        z-index: 4;
} */

/* .line::before {
        content: '';
        display: block;
        position: absolute;
        width: 115%;
        height: max(100%,15vw);
        bottom: -30%;
        left: -20px;
        transform: rotate(4deg);
        background-color: hsl(0, 0%, 100%);
        z-index: 4;
}
     */

/*animations*/
@keyframes scalling {
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.1);
  }
}
@keyframes scalling2 {
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

/*media*/

@media (max-width: 900px) {
  .hero-img {
    width: 350px;
  }
  /* section.homeSection .content h1 {
    font-size: 1.3rem;
  }
  section.homeSection .content p {
    font-size: 0.9rem;
  } */
  section.homeSection .content {
    width: calc(100vw - 350px - 2em);
  }
}

@media (max-width: 600px) {
  .homeSection .mainContainer {
    max-width: 1700px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  section.homeSection .content {
    padding: 0;
    display: block;
    position: absolute;
    width: calc(100vw - 2 * 3em);
    top: 15%;
    text-align: center;
    margin: 0 3em;
  }
  .hero-img {
    margin-right: 300px;
    opacity: 0;
  }
}
