
.medicalNetwork .container{
    padding: 30vh 5vw 10vh 5vw;
    display: 'flex';
    flex-direction: 'column';
    justify-content: 'center';
    height: 80vh;
}
.medicalNetwork h1{
    color: #fff;
}.medicalNetwork .banner p{
    color: #fff;

}
.table-section {
    padding: 0em 5vw;
}
.table-section h1 {
    color: var(--primary-color1);
    margin: 1em 0;
}