.post {
  background-color: var(--bg-light);
  padding: 1em;
  margin: 1em;
  max-width: 600px;
  border-radius: 10px;
}

.post p {
  margin-bottom: 1em;
}

.post .title {
  font-weight: 500;
  font-size: larger;
}

.post img {
  width: 100%;
}
.post video {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .post {
    margin: 0.5em 0;
    border-radius: 0;
    width: 100vw;
  }
}
